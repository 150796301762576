import React, { useEffect, useRef, useState } from 'react'
import { ChevronLeft, ChevronRight } from '@tamagui/lucide-icons'
import isNil from 'lodash.isnil'
import type { NativeScrollEvent, NativeSyntheticEvent } from 'react-native'
import { FlatList } from 'react-native'
import { Pressable } from 'react-native'
import { SolitoImage as Image } from 'solito/image'
import { useRouter } from 'solito/router'
import { IMAGE_CODE_FROM_PNG_PIXEL_GRAY } from '@centrito/common/constants'
import useWindowDimensions from '@centrito/app/utils/hooks/useWindowDimensions'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'
import { Circle, Text, XStack, debounce } from '@centrito/ui/src'

export type ImageSources = {
  url: string
  urlWebP: string | null
  alt: string
  href?: string | null
}[]

interface BaseImageSliderProps {
  automaticScrollTime?: number
  isCarouselFullscreen?: boolean
  width?: number
  gap?: number
  margin?: number
  borderRadius?: number
  hasCounterDots?: boolean
  setIsCarouselFullscreen?: React.Dispatch<React.SetStateAction<boolean>>
  imageSources: ImageSources
  imageIndex: number
  setImageIndex: React.Dispatch<React.SetStateAction<number>>
  nextImage: () => void
  previousImage: () => void
  aspectRatio?: number
  isMarginTopDisabled?: boolean
  isProductPage?: boolean
  windowHeight?: number
  onPress?: () => void
  DiscountTag?: React.ReactNode
  subhome: string
}

enum ScrollDirection {
  NEXT = 'NEXT',
  PREV = 'PREV',
}

const useInteractionStateWithCallback = (
  initialValue: boolean,
): [boolean, (newValue: any, callback: any) => void] => {
  const [value, setValue] = useState<boolean>(initialValue)

  const setValueAndCallback = (newValue: boolean, callback?: () => void): boolean | void => {
    setValue(() => {
      if (callback) {
        callback()
      }
      return newValue
    })
  }

  return [value, setValueAndCallback]
}

export const BaseImageSlider: React.FC<BaseImageSliderProps> = ({
  automaticScrollTime = 0,
  borderRadius = 0,
  width,
  hasCounterDots = true,
  isCarouselFullscreen = false,
  setIsCarouselFullscreen = (): void => undefined,
  gap = 0,
  margin = 0,
  aspectRatio = 1,
  imageIndex,
  setImageIndex,
  imageSources,
  isMarginTopDisabled = false,
  isProductPage = false,
  windowHeight,
  onPress,
  DiscountTag = null,
  subhome,
}): JSX.Element => {
  const [isControlled, setIsControlled] = useInteractionStateWithCallback(false)
  const [scrollDirecion, setScrollDirection] = useState<ScrollDirection | undefined>(undefined)
  const scrollViewRef = useRef<FlatList>(null)
  const { width: _windowWidth } = useWindowDimensions()
  const windowWidth = !isNil(width) ? width : _windowWidth - margin * 2
  const buttonShadowProps = {
    elevation: 10,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 10,
    shadowColor: '#0000001E',
  }
  const router = useRouter()

  const scrollToIndex = (newIndex: number): void => {
    const currentRef = scrollViewRef.current
    if (currentRef) {
      currentRef.scrollToOffset({
        offset: newIndex * windowWidth,
        animated: true,
      })
    }
  }

  const debouncedHandleScroll = debounce((contentOffset: number) => {
    if (!isCarouselFullscreen) {
      const newIndex = Math.round(contentOffset / windowWidth)
      if (newIndex !== imageIndex) {
        setImageIndex(newIndex)
        setIsControlled(true, undefined)
      }
    }
  }, 100)

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>): void => {
    const contentOffset = event.nativeEvent.contentOffset.x
    debouncedHandleScroll(contentOffset)
  }

  useEffect((): void => {
    if (scrollDirecion === ScrollDirection.NEXT) {
      setImageIndex((imageIndex + 1) % imageSources.length)
      setScrollDirection(undefined)
    } else if (scrollDirecion === ScrollDirection.PREV) {
      setImageIndex(imageIndex ? imageIndex - 1 : 0)
      setScrollDirection(undefined)
    }
    setIsControlled(true, undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollDirecion])

  useEffect((): void => {
    if (!isControlled) {
      setIsControlled(isCarouselFullscreen, undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCarouselFullscreen])

  // function that changes the image index on a time out, only if the user hasnt clicked to change image
  useEffect((): (() => void) | void => {
    if (automaticScrollTime) {
      const timeoutId = setTimeout(() => {
        if (imageIndex < imageSources.length - 1 || imageIndex === 0) {
          setImageIndex(imageIndex + 1)
        }
        if (imageIndex === imageSources.length - 1) {
          setImageIndex(0)
        }
      }, automaticScrollTime)
      return () => clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageIndex])

  // if the user has clicked to change the image, reset the time out.
  useEffect((): (() => void) | void => {
    if (isControlled) {
      const timeoutId = setTimeout(() => setIsControlled(false, undefined), automaticScrollTime / 2)
      return () => clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isControlled])

  useEffect(
    (): void => {
      scrollToIndex(imageIndex)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageIndex],
  )

  const prevImageSourcesRef = useRef<ImageSources>()
  useEffect(() => {
    prevImageSourcesRef.current = imageSources
  })
  const prevImageSources = prevImageSourcesRef.current

  useEffect((): void => {
    if (JSON.stringify(prevImageSources) !== JSON.stringify(imageSources)) {
      setIsControlled(true, () => {
        setImageIndex(0)
        scrollToIndex(0)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSources])

  const handleOnPress = (): void => {
    if (!isNil(onPress)) {
      onPress()
    }
    setIsCarouselFullscreen(true)
  }

  const handleBannerClick = (imageHref: string, imageAlt: string, index: number): void => {
    posthogClient.captureCustomEvent('shopper_banner_clicked', {
      banner_alt: imageAlt,
      banner_position: index + 1,
      subhome: subhome == '/' ? 'Home' : subhome,
    })
    if (imageHref) {
      router.push(imageHref)
    }
  }

  const maxHeight = windowHeight ? windowHeight * 0.68 : null

  return (
    <XStack
      width="100%"
      maxWidth={windowWidth}
      maxHeight={maxHeight}
      display="flex"
      flexDirection="column"
      margin={margin}
      marginTop={isMarginTopDisabled ? 0 : margin}
    >
      <XStack
        width="100%"
        height={windowWidth / aspectRatio}
        maxHeight={maxHeight}
        display="flex"
        position="relative"
        zIndex={0}
      >
        {imageIndex < imageSources.length - 1 && (
          <XStack
            position="absolute"
            zIndex={998}
            top="42%"
            right={16}
            justifyContent="center"
            onPress={(): void => {
              setIsControlled(true, () => setScrollDirection(ScrollDirection.NEXT))
            }}
          >
            <Circle
              size={34}
              backgroundColor="#FAFAFA"
              display="flex"
              alignItems="center"
              justifyContent="center"
              {...buttonShadowProps}
            >
              <ChevronRight color="#011627" size={24} />
            </Circle>
          </XStack>
        )}
        {imageIndex > 0 && (
          <XStack
            position="absolute"
            zIndex={998}
            top="42%"
            left={16}
            justifyContent="center"
            onPress={(): void => {
              setIsControlled(true, () => setScrollDirection(ScrollDirection.PREV))
            }}
          >
            <Circle
              size={34}
              backgroundColor="#FAFAFA"
              display="flex"
              alignItems="center"
              justifyContent="center"
              {...buttonShadowProps}
            >
              <ChevronLeft color="#011627" size={24} />
            </Circle>
          </XStack>
        )}
        {!isNil(DiscountTag) && (
          <XStack position="absolute" bottom={16} left={16} zIndex={998}>
            {DiscountTag}
          </XStack>
        )}
        <XStack width="100%" height="100%" display="block">
          {hasCounterDots && (
            <XStack
              position="absolute"
              bottom={16}
              right={16}
              zIndex={99}
              backgroundColor="#0C0C0C3D"
              paddingHorizontal={9}
              paddingVertical={2}
              borderRadius={10}
            >
              <Text fontFamily="$poppinsFont" color="#FAFAFA" fontSize={15}>
                {imageIndex + 1}/{imageSources.length}
              </Text>
            </XStack>
          )}
          <XStack animation="quick" position="relative" height="100%" gap={gap}>
            <FlatList
              horizontal
              pagingEnabled
              showsHorizontalScrollIndicator={false}
              disableIntervalMomentum
              data={imageSources}
              keyExtractor={(item, index): string => `${index}-${item.alt}`}
              onScroll={handleScroll}
              ref={scrollViewRef}
              decelerationRate="fast"
              renderItem={({ item: image, index }): JSX.Element => {
                return (
                  <Pressable
                    onPress={(): void => {
                      if (isProductPage) {
                        handleOnPress()
                      } else {
                        handleBannerClick(image.href, image.alt, index)
                      }
                    }}
                  >
                    <Image
                      src={image.urlWebP ?? image.url}
                      alt={image.alt}
                      contentFit={isProductPage ? 'contain' : 'fill'}
                      placeholder="blur"
                      blurDataURL={IMAGE_CODE_FROM_PNG_PIXEL_GRAY}
                      style={{ borderRadius, maxHeight }}
                      height={windowWidth / aspectRatio}
                      width={windowWidth}
                    />
                  </Pressable>
                )
              }}
            />
          </XStack>
        </XStack>
      </XStack>
    </XStack>
  )
}
